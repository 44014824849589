import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "row g-6 mb-6 g-xl-9 mb-xl-9" }
const _hoisted_2 = { class: "card" }
const _hoisted_3 = { class: "card-header border-0 pt-5 pb-5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Card3 = _resolveComponent("Card3")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("h5", null, "Total Users: " + _toDisplayString(_ctx.users.length), 1),
        _createElementVNode("input", {
          onInput: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.search && _ctx.search(...args))),
          type: "search",
          name: "search",
          class: "form-control form-control-lg form-control-solid mb-3 mb-lg-0",
          placeholder: "Search for user"
        }, null, 32)
      ])
    ]),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filtered, (user, index) => {
      return (_openBlock(), _createBlock(_component_Card3, {
        key: index,
        name: `${user.name}`,
        id: user.uid,
        status: user.status,
        position: user.email,
        online: user.verified,
        avatar: user.picture,
        phone: user.phone,
        connected: user.verified,
        "avg-earnings": user.points,
        "total-sales": user.redeemed,
        onRefresh: _ctx.refresh
      }, null, 8, ["name", "id", "status", "position", "online", "avatar", "phone", "connected", "avg-earnings", "total-sales", "onRefresh"]))
    }), 128))
  ]))
}