
import { defineComponent } from "vue";
import Card3 from "@/components/cards/Card3.vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import { useToast } from "vue-toastification";

export default defineComponent({
    name: "users",
    components: {
        Card3,
    },
    data(){
        return {
            users: [],
            filtered: []
        }
    },
    methods: {
        refresh(event, value){
            this.fetchusers()
        },
        search(e){
            let query = e.target.value

            this.filtered = this.users.filter(user=>user.email.toLowerCase().includes(query.toLowerCase()) || user.name.toLowerCase().includes(query.toLowerCase()))
        },
        fetchusers(){
            this.filtered = []
            this.users = []
            this.axios.get('/leaderboard').then(({data})=>{
                this.filtered = data.leaderboard
                this.users = data.leaderboard
            }).catch(e=>{
                try{
                    e.response.status == 401 ? this.$router.replace('/sign-in') : useToast().error(e.response.data.message)
                }catch(err){
                    useToast().warning(e.message)
                }
            })
        }
    },
    mounted(){
        setCurrentPageTitle('Users')
    },
    created(){
        this.fetchusers()
    }
});
